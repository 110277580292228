body {
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  font-family: Roboto;
  background: #f7f7f7;
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

main {
  width: 100%;
}

#root {
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.switch-wrapper {
  position: relative;
  height: 100%;
}

.switch-wrapper.switch-wrapper-search {
  height: calc(100% - 81px);
}

.switch-wrapper.switch-without-search-bar {
  height: 100%;
  overflow: hidden;
}

.switch-wrapper > div {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
}

.switch-wrapper-app {
  position: relative;
  height: 100%;
}

.switch-wrapper-app > div {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
}

.walkthrough-backdrop.show {
  background: rgb(19 57 118);
  display: flex;
}

.walkthrough-backdrop.show > div {
  margin-top: auto !important;
}

img {
  touch-action: manipulation;
}

.ContentPreview .container {
  padding: 8px;
}

#root.--prevent-animation .switch-wrapper-app > div {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.walkthrough-modal {
  margin: auto;
}

@media only screen and (min-width: 800px) {
  .container {
    width: 900px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
  }
}

@media only print {
  .hidden-print {
    display: none !important;
  }
  * {
    overflow: visible !important;
    box-shadow: none !important;
  }
  html {
    visibility: hidden !important;
    display: none !important;
  }
}
